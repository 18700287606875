import type {
  DataLayerCalculation,
  DataLayerCalculationExternal,
  DataLayerPageView,
  DataLayerPageViewExternal,
} from "./dataLayerEvents.types";

const submitPageViewEvent = ({
  pageReferrer,
  contentGroup,
  pageLanguage,
  vinregType,
  vinRecords,
  reportCombination,
}: DataLayerPageView): void => {
  const eventParameters: DataLayerPageViewExternal = {
    event: "page_view",
    page_location: window.location.href,
    page_referrer: pageReferrer,
    content_group: contentGroup,
    page_language: pageLanguage,
    vin_type: vinregType,
    vin_records: vinRecords,
    report_combination: reportCombination,
  };
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(eventParameters);
};

const submitBolloCalculationEvent = ({ event, errorMessage }: DataLayerCalculation): void => {
  const eventParameters: DataLayerCalculationExternal = errorMessage
    ? {
        event: event,
        error_message: errorMessage,
      }
    : {
        event: event,
      };
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(eventParameters);
};

const submitViewSampleReportEvent = (): void => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "view_sample_report",
  });
};

export const dataLayerEvents = {
  submitPageViewEvent,
  submitBolloCalculationEvent,
  submitViewSampleReportEvent,
};
